import { useState, MouseEvent } from "react";
import "./App.css";

const defaultProviderId = "ob-monzo";
const baseEndpoint = `https://api.test-redirect.davidhayden.co.uk`;

function App() {
  const [submittingForm, setSubmittingForm] = useState(false);
  const [providerId, setProviderId] = useState(defaultProviderId);
  const [redirectUri, setRedirectUri] = useState("");
  const [delayMs, setDelayMs] = useState(0);

  const submitForm = (event: MouseEvent<HTMLInputElement>) => {
    setSubmittingForm(true);
    if (event.currentTarget.form?.requestSubmit) {
      event.currentTarget.form?.requestSubmit();
    } else {
      event.currentTarget.form?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const submitAjax = (n: number) => (event: MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSubmittingForm(true);
    fetch(`${baseEndpoint}/createPayment?delayMs=${n}&response=json`, {
      method: "POST",
      body: new URLSearchParams({
        provider_id: providerId,
      }),
      redirect: "error",
    })
      .then((response) => {
        response
          .json()
          .then((json) => (window.location.href = json.redirect_uri));
      })
      .catch((error) => {
        alert("Error! See console for details.");
        console.log(error);
        setSubmittingForm(false);
      });
  };

  /* 

  const standardSubmitAjax =
    (cb: any) => (event: MouseEvent<HTMLInputElement>) => {
      setSubmittingForm(true);
      fetch(`${baseEndpoint}/createPayment?delayMs=${delayMs}&response=json`, {
        method: "POST",
        body: new URLSearchParams({
          provider_id: providerId,
        }),
        redirect: "error",
      })
        .then((response) => {
          response.json().then(cb);
        })
        .catch((error) => {
          alert("Error! See console for details.");
          console.log(error);
          setSubmittingForm(false);
        });
    };
    
  const submitAjaxThenGet = standardSubmitAjax(
    (json: { redirect_uri: string }) =>
      (window.location.href = `${baseEndpoint}/wrapRedirect?redirect_uri=${encodeURIComponent(
        json.redirect_uri
      )}`)
  );

  const submitAjaxThenGetWithATwist = standardSubmitAjax(
    (json: { redirect_uri: string }) => {
      window.location.assign(
        `${baseEndpoint}/wrapRedirect?redirect_uri=${encodeURIComponent(
          json.redirect_uri
        )}`
      );
    }
  );

  const submitAjaxThenPost = (event: MouseEvent<HTMLInputElement>) => {
    setSubmittingForm(true);
    fetch(`${baseEndpoint}/createPayment?delayMs=${delayMs}&response=json`, {
      method: "POST",
      body: new URLSearchParams({
        provider_id: providerId,
      }),
      redirect: "error",
    })
      .then((response) => {
        response.json().then((json) => setRedirectUri(json.redirect_uri));
      })
      .catch((error) => {
        alert("Error! See console for details.");
        console.log(error);
        setSubmittingForm(false);
      });
  }; */

  const onRedirectFormRef = (form: HTMLFormElement | null) => {
    if (!submittingForm || redirectUri === "") {
      return;
    }
    if (form?.requestSubmit) {
      form?.requestSubmit();
    } else {
      form?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <div className="App">
      <header>
        <h1>Redirect Test</h1>
      </header>
      <main>
        <section>
          <div className="inputWrapper">
            <label htmlFor="provider_id">Provider:</label>
            <select
              id="provider_id"
              name="provider_id"
              disabled={submittingForm}
              onChange={(x) => setProviderId(x.target.value)}
              defaultValue={defaultProviderId}
            >
              <option value="ob-aib-gb-corporate">
                Allied Irish Bank Corporate
              </option>
              <option value="ob-barclays">Barclays</option>
              <option value="ob-barclays-business">Barclays Business</option>
              <option value="ob-boi">Bank of Ireland UK</option>
              <option value="ob-bos">Bank of Scotland</option>
              <option value="ob-bos-business">Bank of Scotland Business</option>
              <option value="ob-cashplus">Cashplus</option>
              <option value="ob-danske">Danske Bank</option>
              <option value="ob-danske-business">Danske Bank Business</option>
              <option value="ob-first-direct">first direct</option>
              <option value="ob-halifax">Halifax</option>
              <option value="ob-hsbc">HSBC</option>
              <option value="ob-hsbc-business">HSBC Business</option>
              <option value="ob-lloyds">Lloyds</option>
              <option value="ob-lloyds-business">Lloyds Business</option>
              <option value="ob-lloyds-commercial">Lloyds Commercial</option>
              <option value="ob-mettle">Mettle Bank</option>
              <option value="ob-monzo">Monzo</option>
              <option value="ob-nationwide">Nationwide</option>
              <option value="ob-natwest">NatWest</option>
              <option value="ob-natwest-business">NatWest Bankline</option>
              <option value="ob-rbs">Royal Bank of Scotland</option>
              <option value="ob-rbs-business">
                Royal Bank of Scotland Bankline
              </option>
              <option value="ob-revolut">Revolut</option>
              <option value="ob-santander">Santander</option>
              <option value="ob-starling">Starling</option>
              <option value="ob-tesco">Tesco Bank</option>
              <option value="ob-transferwise">Wise</option>
              <option value="ob-tsb">TSB</option>
              <option value="ob-ulster">Ulster Bank</option>
              <option value="ob-ulster-business">Ulster Bankline</option>
              <option value="ob-virgin-money-merged">
                Virgin Money (Merged)
              </option>
            </select>
          </div>
          <br />
          <hr />
          <br />
          <div className="buttons">
            <form
              action={`${baseEndpoint}/createPayment?delayMs=${5000}`}
              method="post"
            >
              <input type="hidden" name="provider_id" value={providerId} />

              <div className="inputWrapper">
                <input
                  type="submit"
                  value="A"
                  disabled={submittingForm}
                  onClick={submitForm}
                />
                <span>302 with 5s delay</span>
              </div>
            </form>

            <form
              action={`${baseEndpoint}/createPayment?delayMs=${15000}`}
              method="post"
            >
              <input type="hidden" name="provider_id" value={providerId} />

              <div className="inputWrapper">
                <input
                  type="submit"
                  value="B"
                  disabled={submittingForm}
                  onClick={submitForm}
                />
                <span>302 with 15s delay</span>
              </div>
            </form>

            <div className="inputWrapper">
              <input
                type="submit"
                value="C"
                disabled={submittingForm}
                onClick={submitAjax(5000)}
              />
              <span>AJAX with 5s delay</span>
            </div>

            <div className="inputWrapper">
              <input
                type="submit"
                value="D"
                disabled={submittingForm}
                onClick={submitAjax(15000)}
              />
              <span>AJAX with 15s delay</span>
            </div>
          </div>
        </section>
        <section>
          <form
            action={`${baseEndpoint}/wrapRedirect`}
            method="post"
            ref={onRedirectFormRef}
          >
            <input type="hidden" name="redirect_uri" value={redirectUri} />
            <input type="submit" value="Pay after AJAX" hidden />
          </form>
        </section>
      </main>

      <div
        className="overlay"
        style={{ visibility: submittingForm ? "visible" : "hidden" }}
      >
        <span>
          Submitting to
          <br />
          {providerId}
        </span>
      </div>
    </div>
  );
}

export default App;
