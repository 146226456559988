
function Return() {
  return (
    <div className="App">
      <header>
        <h1>Redirect Test</h1>
      </header>
      <main>
        Thanks!
      </main>
    </div>
  );
}

export default Return